import styled from 'styled-components'
import { colors } from '../../utils/global'

export const Container = styled.div`
  padding: 80px 16px;
  width: 100%;
  ${({ bg }) => bg && `background: ${bg};`}
  ${({ paddingZero }) => paddingZero && `padding: 0 16px;`}
`

export const Wrapper = styled.div`
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 16px;
  ${({ bg }) => bg && `background: ${bg};`}
`

export const Title = styled.h2`
  margin: ${({ margin }) => margin || 0};
  font-weight: 800;
  font-size: 20px;
  color: ${({ color }) => color || `${colors.bayOfMany}`};
  text-align: ${({ align }) => align || 'initial'};
`

export const Text = styled.p`
  margin: ${({ margin }) => margin || 0};
  font: normal normal 500 18px/22px 'Montserrat';
  color: ${({ color }) => color || `${colors.shark}`};
  text-align: ${({ align }) => align || 'initial'};
`

export const TIWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > div:first-child {
    width: 39%;
  }

  & > div:last-child {
    width: 59%;
  }

  @media (max-width: 768px) {
    display: block;

    & > div {
      width: 100% !important;
    }
  }
`

export const SchoolWrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
`

export const Structure = styled(Wrapper)`
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: 50%;

    p {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`

export const StructureImages = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;

  @media (max-width: 768px) {
    flex-direction: row;
    padding-right: 0;
    padding-top: 80px;
  }
`

export const SImage = styled.div`
  width: calc(50% + 20px);

  &:first-child {
    align-self: flex-end;
    margin-left: -20px;
  }

  &:nth-child(2) {
    margin-top: -80px;
  }

  &:last-child {
    align-self: flex-end;
    margin-top: -80px;
  }

  @media (max-width: 768px) {
    width: calc(33% + 40px);

    &:first-child {
      align-self: auto;
      margin-left: 0;
    }

    &:nth-child(2) {
      margin-left: -40px;
    }

    &:last-child {
      align-self: auto;
      margin-top: 0;
      margin-left: -40px;
    }
  }
`

export const Items = styled.div`
  display: flex;
  align-items: center;

  i {
    width: 24px;
    margin-right: 8px;
  }

  span {
    color: ${colors.bayOfMany};
    font-weight: bold;
  }
`

export const EducationalMission = styled.div`
  display: flex;
  justify-content: center;
  max-width: 880px;
  width: 100%;
  margin: 0 auto;
  align-items: center;

  img {
    margin-right: 80px;
  }

  div {
    h2 {
      color: ${colors.bayOfMany};
      font-weight: 500;
      margin: 0 0 24px;

      span {
        font-weight: 900;
      }
    }
  }

  @media (max-width: 768px) {
    display: block;
    text-align: center;

    img {
      margin: 0 0 20px 24px;
    }
  }
`

export const MissionValue = styled.div`
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
  align-items: center;
  border: 2px solid #707070;
  display: flex;
  justify-content: center;
  position: relative;

  & > div {
    width: calc(50%);
    align-self: flex-start;

    & > div:first-child {
      background: #e9e9e9;
      padding: 30px;

      h2 {
        margin: 0;
        text-align: center;
        font-size: 24px;
        line-height: 30px;
        color: #1e1e21;
      }
    }

    p {
      margin: 36px;
      text-align: center;

      @media (max-width: 460px) {
        font-size: 13px;
        margin: 10px;
      }
    }
  }
  span {
    display: block;
    width: 1px;
    background: #707070;
    height: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

export const Modal = styled.div`
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #ffffffaa;

  ${({ show }) => show && 'display: none;'}
`
