import React, { useState } from 'react'
import Layout from '../components/Layout/'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import * as S from '../components/OExatoStyles/'
import * as GS from '../components/GlobalStyles/styles'

import target from '../img/arrow-target.svg'

const OExato = () => {
  const [showModal, setShowModal] = useState(false)
  const { check, exato, quadra, biblioteca, sala, profa } = useStaticQuery(
    graphql`
      query {
        quadra: file(relativePath: { eq: "temp-quadra.png" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        biblioteca: file(relativePath: { eq: "temp-biblioteca.png" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sala: file(relativePath: { eq: "temp-sala.png" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        check: file(relativePath: { eq: "colegio-exato-check-mark.png" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        exato: file(relativePath: { eq: "colegio-exato-fachada.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        profa: file(relativePath: { eq: "professora-maria-helena.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <Layout>
      <S.Wrapper>
        <S.Title align="center">Bem-vindos ao Colégio Exato</S.Title>
        <S.Text margin="12px 0">
          O Colégio Exato surge dos ideais de uma professora Maria Helena Macêdo
          de Mendonça Gomes, que em 1999 iniciou suas atividades com “Exato
          Vestibulares,” em um prédio com quatro salas de cursinho pré-
          vestibular e uma lista de espera.
        </S.Text>
        <S.TIWrapper>
          <div>
            <Img fluid={profa.childImageSharp.fluid} />
            <S.Text align="center" margin="0">
              Professora Maria Helena
            </S.Text>
          </div>
          <div>
            <S.Text margin="12px 0">
              Com o sucesso de aprovação nos vestibulares, nossos projetos se
              ampliam. Em 2000 surge o “Colégio Exato”, seu projeto nasce da
              vontade, da experiência e do desejo de contribuir para formação do
              ser humano, como ser ético, autônomo, que respeita as diferenças
              entre as pessoas e promove atitude de criticidade e de cooperação.
            </S.Text>
            <S.Text margin="12px 0">
              Com o projeto inicial bem sucedido, nasce um novo desejo, de
              sonhos, de mudanças, de conhecimentos e experiências. Em 2002
              mudamos de endereço, para um prédio maior, com mais salas,
              ampliamos e aprimoramos o nosso trabalho.
            </S.Text>
            <S.Text margin="12px 0">
              Com a continuação dos ideais, em 2006 demos mais um passo
              importante, numa parceria de sucesso implantamos a UNOPAR, a maior
              e melhor Universidade de Ensino a Distancia do país.
            </S.Text>
          </div>
        </S.TIWrapper>
        <S.Text margin="12px 0">
          Em 2013 o Colégio Exato amplia sua proposta educacional, investindo
          também em uma moderna infraestrutura para acolher melhor nossos
          alunos, mudamos para novo endereço, agora, para sede própria.
        </S.Text>
        <S.Text margin="12px 0">
          Acreditando sempre que, “juntos somos mais fortes” os diretores José
          Pedro e Maria Helena, com as bênçãos de DEUS, com o apoio de suas
          filhas, Paula Fernanda e Jéssica, contam com a valiosa contribuição de
          sua equipe, administrativa e técnico- pedagógica; Daniela, Irene,
          Vilma e Sônia além de uma equipe de professores altamente qualificada.
          O Colégio oferece o Ensino Fundamental II (6º ao 9º) Ano e Ensino
          Médio (1ª a 3ª) Série, com aulas regulares pela manhã, sendo que no
          turno vespertino oferecemos educação física, aulas de música, oficina
          de redação, provas e simulados além de aulas complementares para o
          TERCEIRÃO. Os conteúdos são planejados bimestralmente e as aulas
          contam com auxilio de tecnologia e parcerias de sucesso. No noturno
          oferecemos mais de 30 cursos de nível superior, cursos de pós
          -graduação, cursos de extensão e cursos livres.
        </S.Text>
        <S.Text margin="12px 0">
          Em todas as etapas de ensino, o Colégio Exato prioriza um ensino
          responsável, de qualidade, enfatizando o desenvolvimento criativo do
          aluno e a junção de suas experiências na coesão de seu EU autônomo com
          o mundo e na manifestação do pensamento afetivo e solidário na sua
          amplitude.
        </S.Text>
        <S.SchoolWrapper>
          <Img fluid={exato.childImageSharp.fluid} />
        </S.SchoolWrapper>
        <S.Text margin="12px 0">
          Acreditamos que ainda há muito o quê sonhar e realizar. Temos certeza
          que amanhã seremos melhores que hoje, pois contamos com interatividade
          e o companheirismo de toda nossa equipe, de nossos alunos e de seus
          familiares.
        </S.Text>
        <S.Text margin="12px 0">
          A realização de um sonho não invalida nem inviabiliza outro sonho, só
          nos dá mais força e coragem, portanto, o sonho continua...
        </S.Text>
      </S.Wrapper>
      <S.Container bg="#F6E803"></S.Container>
      <S.Structure>
        <S.StructureImages>
          <S.SImage>
            <Img fluid={biblioteca.childImageSharp.fluid} />
          </S.SImage>
          <S.SImage>
            <Img fluid={sala.childImageSharp.fluid} />
          </S.SImage>
          <S.SImage>
            <Img fluid={quadra.childImageSharp.fluid} />
          </S.SImage>
        </S.StructureImages>
        <div>
          <S.Title margin="0 0 24px">Nossa Estrutura</S.Title>
          <S.Text>
            Localizado numa Avenida de fácil acesso e no centro da cidade, o
            Colégio Exato possui uma ampla e privilegiada estrutura que
            proporciona segurança, conforto e praticidade para os alunos.
          </S.Text>
          <S.Text>
            Nossa estrutura foi pensada com detalhes para que os alunos possam
            tirar o máximo de proveito da experiência de aprendizagem.
          </S.Text>
          <S.Text>O Colégio Exato conta com:</S.Text>
          <S.Items>
            <i>
              <Img fluid={check.childImageSharp.fluid} />
            </i>{' '}
            <span>Biblioteca</span>
          </S.Items>
          <S.Items>
            <i>
              <Img fluid={check.childImageSharp.fluid} />
            </i>{' '}
            <span>Quadra Poliesportiva</span>
          </S.Items>
          <S.Items>
            <i>
              <Img fluid={check.childImageSharp.fluid} />
            </i>{' '}
            <span>Laboratório de Informática</span>
          </S.Items>
          <S.Items>
            <i>
              <Img fluid={check.childImageSharp.fluid} />
            </i>{' '}
            <span>Sala de Aula com Kit Multimídia</span>
          </S.Items>
          <S.Items>
            <i>
              <Img fluid={check.childImageSharp.fluid} />
            </i>{' '}
            <span>Aula de música</span>
          </S.Items>
          <S.Items>
            <i>
              <Img fluid={check.childImageSharp.fluid} />
            </i>{' '}
            <span>Cantinho da leitura</span>
          </S.Items>
        </div>
      </S.Structure>
      <S.Container bg="#00000029">
        <S.EducationalMission>
          <img src={target} alt="Missão Educacional" />
          <div>
            <h2>
              Conheça nossa
              <br />
              <span>Missão Educacional</span>
            </h2>
            <GS.YellowButton onClick={() => setShowModal(true)}>
              Clique para ler
            </GS.YellowButton>
          </div>
        </S.EducationalMission>
      </S.Container>
      <S.Container>
        <S.MissionValue>
          <div>
            <div>
              <h2>Missão</h2>
            </div>
            <div>
              <p>
                Promover uma educação de qualidade, que garanta as aprendizagens
                essenciais para a formação de cidadãos autônomos, éticos,
                críticos e participativos, capazes de atuar com competência,
                dignidade e responsabilidade na sociedade em que vivem.
              </p>
            </div>
          </div>
          <div>
            <div>
              <h2>Valores</h2>
            </div>
            <div>
              <p>
                Acreditamos que uma das funções da Educação é possibilitar a
                mudança de atitudes frente à vida. Portanto é fundamental o
                envolvimento da família junto à escola para que o
                desenvolvimento de seus filhos, nossos alunos, ocorra de forma
                harmoniosa em todos os aspectos sejam eles: afetivo, cognitivo
                ou biológico. Por isso convidamos a todos os pais e ou
                responsáveis a participarem conosco.
              </p>
            </div>
          </div>
          <span></span>
        </S.MissionValue>
      </S.Container>
      <S.Container bg="#2D2E92" paddingZero>
        <S.Wrapper>
          <S.Title color="white" align="center">
            <i
              style={{
                width: '40px',
                filter: 'brightness(0) invert(1)',
                display: 'inline-block'
              }}
            >
              <Img fluid={check.childImageSharp.fluid} />
            </i>
          </S.Title>

          <S.Title color="white" align="center">
            Nossa visão
          </S.Title>
          <S.Text align="center" margin="20px 0" color="white">
            São inúmeros os desafios a serem enfrentados para que a escola
            realmente desenvolva o seu papel no processo educacional. Necessário
            se faz promover meios para melhorar a qualidade do ensino, adequar o
            conteúdo à nossa realidade.
          </S.Text>
          <S.Text align="center" margin="20px 0" color="white">
            Oferecer um ensino de qualidade promovendo um espaço de convivência
            social em que alunos, professores e equipe técnica-pedagógica possam
            cooperar entre si, construir sua identidade, preservar suas
            especialidades culturais, respeitando o pluralismo, criando meios
            eficientes para o tratamento dos conteúdos escolares, utilizando
            técnicas de estudo dirigido e recursos tecnológicos e audiovisuais.
            Garantir aos professores a formação continuada, momentos para
            discutir suas práticas, dando condições para que possam ter acesso
            às informações mais atualizadas na área de educação e à cultura de
            uma forma mais ampla: desenvolver políticas de valorização dos
            mesmos visando a melhoria de condições de trabalho. Melhorar as
            condições físicas da escola dotando-a de recursos didáticos e
            ampliar as possibilidades de uso de novas tecnologias.
          </S.Text>
          <S.Text align="center" margin="20px 0" color="white">
            É necessário estimular de fato, o desenvolvimento e a participação
            democrática e efetiva de toda comunidade escolar, criando mecanismos
            que favoreçam o seu envolvimento nos projetos educativos da escola.
          </S.Text>
        </S.Wrapper>
      </S.Container>
      <S.Modal show={showModal}>asd</S.Modal>
    </Layout>
  )
}

export default OExato
